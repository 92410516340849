import _get from 'lodash/get';

export const ENDPOINT = process.env.API_ENPOINT;
export const PRODUCTION = 'https://api.fast.vn';
export const BETA = 'https://apibeta.fast.vn';

const CONFIG = {
  [PRODUCTION]: {
    CATEGORIES: [
      {
        id: 170,
      },
      {
        id: 171,
      },
      {
        id: 172,
      },
      {
        id: 244,
      },
      {
        id: 193,
      },
      {
        id: 166,
      },
      {
        id: 197,
      },
      {
        id: 167,
      },
      {
        id: 169,
      },
      {
        id: 396,
      },
      // {
      //   id: 401,
      //   bannerUrl:
      //     '/assets/banner-category-04-11-2022/BANNER_DDHS_1200x250px.jpg',
      //   bannerThumbnailUrl: '/assets/banner-category-mobile/BANNER_DSHS.jpg',
      // },
    ] as ICategoryItem[],
    TOP_CATEGORIES: [165, 166, 213, 401, 168, 192, 167, 197],
    TOP_KEYWORDS: [
      'giấy in',
      'giấy a4',
      'chuột máy tính',
      'máy tính casio',
      'giấy a3',
      'mực in',
      'giấy in a4',
      'khắc dấu',
      'mực in canon',
      'giấy double a',
      'excel',
      'double a',
      'làm con dấu',
      'làm con dấu tên',
      'hộp mực máy in canon 2900',
      'giấy a4 double a 80gsm',
      'băng keo trong',
      'súng bắn keo',
      'thùng rác',
      'giay decal a4',
      'cây lau nhà',
      'giấy a4 giá rẻ',
      'giấy a4 plus',
    ],
    FACEBOOK_PAGE_ID: '512878708840284',
    FACEBOOK_APP_ID: '579496382656080',
    GOOGLE_APP_ID:
      '936290879431-cav6981mkchp1pob0mvhfrvdek8tmpsh.apps.googleusercontent.com',
    LOGIN_SOCIAL: true,
    ENABLED_FACEBOOK: true,
    ENABLED_GOOGLE: true,
    GRAPHQL_URL: 'https://fast.vn/graphql',
    GRAPHQL_URL_DEV: 'http://localhost:4000/graphql',
    REDIRECT_URI: 'https://fast.vn',
    HOST_URL: 'https://fast.vn',
    ZALO_APP_ID: '543400243067265248',
    ZALO_SECRET_KEY: 'L8zXVkMOiCpXnV4HOaM5',
    ZALO_REDIRECT_URI: 'https://fast.vn/login_zalo',
    PIXEL_ID: '135622043739145',
    NO_SEO: false,
    SENTRY_DNS:
      'https://5a6779d8cdec4c3c9dd570aa9f4e32f8@o385482.ingest.sentry.io/5283697',
    SHOP_SHOPEE_ID: '242520587',
    GOOGLE_GTM_ID: 'GTM-N2K6B7W',
    GA_TRACKING_ID: 'UA-159286877-1',
    CONTENT_ENDPOINT: 'https://content.fast.vn',
    ENABLE_HOTJAR: true,
    SECRET_KEY_UPLOAD: 'w3MzUf14BMELVDhCm0CP9yIEWTO6Jkw',
    API_ENDPOINT: process.env.API_ENPOINT || 'https://api.fast.vn',
  },
  [BETA]: {
    CATEGORIES: [
      {
        id: 170,
        bannerUrl: '/assets/banner-category/BANNER_PAPER_1200x250px.jpg',
        bannerThumbnailUrl:
          '/assets/banner-category-mobile/BANNER_PAPER-01.jpg',
      },
      {
        id: 213,
        bannerUrl: '/assets/banner-category/BANNER_DVKD_1200x250px.jpg',
        bannerThumbnailUrl:
          '/assets/banner-category-mobile/BANNER_DVKD_1200x250px.jpg',
      },
      {
        id: 209,
        bannerUrl: '',
        bannerThumbnailUrl: '',
      },
      {
        id: 196,
        bannerUrl: '',
        bannerThumbnailUrl: '',
      },
    ] as ICategoryItem[],
    TOP_CATEGORIES: [165, 166, 213, 401, 168, 192, 167, 197],
    TOP_KEYWORDS: [
      'giấy in',
      'giấy a4',
      'chuột máy tính',
      'máy tính casio',
      'giấy a3',
      'mực in',
      'giấy in a4',
      'khắc dấu',
      'mực in canon',
      'giấy double a',
      'excel',
      'double a',
      'làm con dấu',
      'làm con dấu tên',
      'hộp mực máy in canon 2900',
      'giấy a4 double a 80gsm',
      'băng keo trong',
      'súng bắn keo',
      'thùng rác',
      'giay decal a4',
      'cây lau nhà',
      'giấy a4 giá rẻ',
      'giấy a4 plus',
    ],
    FACEBOOK_PAGE_ID: '512878708840284',
    FACEBOOK_APP_ID: '579496382656080',
    GOOGLE_APP_ID:
      '936290879431-cav6981mkchp1pob0mvhfrvdek8tmpsh.apps.googleusercontent.com',
    LOGIN_SOCIAL: true,
    ENABLED_FACEBOOK: true,
    ENABLED_GOOGLE: true,
    GRAPHQL_URL: 'https://betav2.fast.vn/graphql',
    GRAPHQL_URL_DEV: 'http://localhost:4000/graphql',
    REDIRECT_URI: 'https://betav2.fast.vn',
    HOST_URL: 'https://betav2.fast.vn',
    ZALO_APP_ID: '543400243067265248',
    ZALO_SECRET_KEY: 'L8zXVkMOiCpXnV4HOaM5',
    ZALO_REDIRECT_URI: 'https://fast.vn/login_zalo',
    PIXEL_ID: '135622043739145',
    NO_SEO: true,
    SENTRY_DNS:
      'https://7fc9e881b433466fbd015f42aa0e675e@o385482.ingest.sentry.io/5218320',
    SHOP_SHOPEE_ID: '242520587',
    IS_BETA: true,
    GOOGLE_GTM_ID: 'GTM-NPMCH3Q',
    GA_TRACKING_ID: '',
    CONTENT_ENDPOINT: 'https://content.fast.vn',
    ENABLE_HOTJAR: false,
    SECRET_KEY_UPLOAD: 'w3MzUf14BMELVDhCm0CP9yIEWTO6Jkw',
    API_ENDPOINT: process.env.API_ENPOINT || 'https://apibeta.fast.vn',
  },
};

export interface ICategoryItem {
  id: number;
  bannerUrl: string;
  bannerThumbnailUrl: string;
}

interface ConfigType {
  CATEGORIES: Array<ICategoryItem>;
  TOP_CATEGORIES: number[];
  TOP_KEYWORDS: string[];
  FACEBOOK_PAGE_ID: string;
  FACEBOOK_APP_ID: string;
  GOOGLE_APP_ID: string;
  LOGIN_SOCIAL: boolean;
  REDIRECT_URI: string;
  ZALO_APP_ID: string;
  ZALO_SECRET_KEY: string;
  ZALO_REDIRECT_URI: string;
  PIXEL_ID: string;
  NO_SEO: boolean;
  SENTRY_DNS: string;
  SHOP_SHOPEE_ID: string;
  GOOGLE_GTM_ID: string;
  GA_TRACKING_ID: string;
  CONTENT_ENDPOINT: string;
  ENABLE_HOTJAR: boolean;
  SECRET_KEY_UPLOAD: string;
  API_ENDPOINT: string;
  [key: string]: any;
}

export const useConfig = (): ConfigType => {
  return _get(CONFIG, [ENDPOINT], {});
};

export default CONFIG;
